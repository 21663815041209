import React from 'react';
import { Link } from 'react-scroll';

const Footer = () => {
  return (
    <footer className='bg-amber-800'>
      <div className='max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8'>
        <div className='mt-8 flex justify-center'>
          <p className='text-base text-white'>
            &copy; {new Date().getFullYear()} Fidus Property. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
