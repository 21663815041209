import React from 'react';
import Imagee from '../../assets/1.png';

const ContactUs = () => {
  return (
    <div
      id='contact'
      className='bg-gray-100 w-full min-h-screen p-2 flex items-center'
    >
      <div className='max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8'>
        <div className='max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8'>
          <div>
            <h2 className='text-2xl font-bold text-gray-900 mb-4'>
              Contact Us
            </h2>
            {/* <p className="text-lg text-gray-700 mb-8">
            If you have any questions or would like to schedule a consultation with one of our real estate experts, please don't hesitate to contact us
            </p> */}
            <ul className='text-lg text-gray-700'>
              {/* <li className='flex items-center mb-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 mr-2 text-amber-800'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'
                  />
                </svg>
                1234 Main St, Anytown USA 12345
              </li> */}
              <li className='flex items-center mb-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  className='h-6 w-6 mr-2 text-amber-800'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75'
                  />
                </svg>
                <a href='mailto:dodeyl2012@gmail.com'>
                  <span>dodeyl2012@gmail.com</span>
                </a>
              </li>
              <li className='flex items-center mb-4'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 mr-2 text-amber-800'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z'
                  />
                </svg>
                <a href='tel:2899978128'>
                  <span>(905) 808-3368</span>
                </a>
              </li>
            </ul>
          </div>
          <div className='mt-12 sm:mt-16 md:mt-0'>
            <img
              src={Imagee}
              alt='Property'
              className='w-full max-h-64 object-cover rounded-t-lg h-1/3 md:h-64'
            />

            {/* <form action='#' method='POST' className='grid grid-cols-1 gap-y-6'>
              <div>
                <label
                  htmlFor='name'
                  className='block text-lg font-medium text-gray-700'
                >
                  Name
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='name'
                    id='name'
                    autoComplete='given-name'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-amber-800 focus:border-amber-800 border border-amber-800 rounded-md'
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='email'
                  className='block text-lg font-medium text-gray-700'
                >
                  Email
                </label>
                <div className='mt-1'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    autoComplete='email'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-amber-800 focus:border-amber-800 border border-amber-800 rounded-md'
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='phone'
                  className='block text-lg font-medium text-gray-700'
                >
                  Phone
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='phone'
                    id='phone'
                    autoComplete='tel'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-amber-800 focus:border-amber-800 border border-amber-800 rounded-md'
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='message'
                  className='block text-lg font-medium text-gray-700'
                >
                  Message
                </label>
                <div className='mt-1'>
                  <textarea
                    id='message'
                    name='message'
                    rows='4'
                    className='py-3 px-4 block w-full shadow-sm focus:ring-amber-800 focus:border-amber-800 border border-amber-800 rounded-md'
                  ></textarea>
                </div>
              </div>
              <div className='flex justify-center'>
                <button
                  type='submit'
                  className='inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-lg font-medium rounded-md text-white bg-amber-800 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-800'
                >
                  Send Message
                </button>
              </div>
            </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
