import React from 'react';
import { Link } from 'react-scroll';

// import Imagee from '../../assets/service1.png';
// import Imageee from '../../assets/service2.png';
// import Imageeee from '../../assets/service3.png';
const PropertyManagement = () => {
  return (
    <div
      id='propertymanagement'
      className='w-full min-h-screen p-2 flex items-center bg-gradient-to-b from-white to-gray-400'
    >
      <div className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
        <h2 className='text-4xl font-bold text-gray-800 mb-6'>
          Property Management <span className='text-amber-800'></span>
        </h2>
        {/* <p className='text-lg text-gray-700 mb-8'>
          We offer real estate services for our clients:
        </p> */}

        <div className='container mx-auto px-4'>
          <div className='flex flex-wrap -mx-4'>
            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imagee}
                  alt='Service 1'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    Application
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    Application Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imageee}
                  alt='Service 2'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    Agreement
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    Agreement Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imagee}
                  alt='Service 1'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    Moving In
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    Moving In Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imagee}
                  alt='Service 1'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    Maintenance Request
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    Maintenance Request Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imageee}
                  alt='Service 2'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    Moving Out
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    Moving Out Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className='w-full md:w-1/3 px-4 mb-8'>
              <div className='bg-white rounded-lg shadow-lg overflow-hidden'>
                {/* <img
                  className='w-full h-48 object-cover object-center'
                  src={Imageee}
                  alt='Service 2'
                /> */}
                <div className='p-4'>
                  <h2 className='text-lg font-bold text-gray-800 mb-2'>
                    My Account
                  </h2>
                  <p className='text-gray-700 leading-relaxed mb-4'>
                    My Account Information.
                  </p>
                  <button className='mt-6 px-4 py-2 bg-amber-800 text-white rounded-md hover:bg-amber-700'>
                    <Link to='contact' smooth={true} duration={500}>
                      Learn More
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyManagement;
