import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './components/pages/home';
import RealEstate from './components/pages/realestate';
import PropertyManagement from './components/pages/propertymanagement';
import Contact from './components/pages/contact';
import './index.css';
function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <RealEstate />
      <PropertyManagement />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
